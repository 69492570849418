import {
    buildSupabaseChainFilter,
    convertKeysToCamelCase,
    convertTutorSnakeToCamelCase,
    handlePageCountCommon,
} from 'utils/utilCommon'
import { supabase } from '../axios.instance'
import { createSupabaseQuery } from '../common'
import { getTutorCountRPC } from '../rpc/rpc.api'

const table = 'tutor_info'

/**
 * 강사 목록
 * @param search
 * @param regionId
 * @param path
 * @returns {Promise<{data}|PostgrestFilterBuilder<*, *, GetResult<*, *, *, "*">[], *>|PostgrestFilterBuilder<*, *, GetResult<*, *, *, "*">[], *>|null>}
 */
export const fetchTutorList = async (search, regionId, path) => {
    try {
        const pageSize = 10
        // query
        const { query } = await buildSupabaseChainFilter(table, search, regionId, path)

        const response = await query
        const searchParams = new URLSearchParams(search)
        const queryParams = new URLSearchParams(query.url.search)
        const params = Object.fromEntries(queryParams.entries())
        const newParams = {
            region_id: params.region_id,
            is_youth: params.is_youth,
            is_retiree: params.is_retiree,
            is_career_gap_female: params.is_career_gap_female,
            is_new: params.is_new,
            is_rehired: params.is_rehired,
            is_advanced_instructor: params.is_advanced_instructor,
            is_foreign: params.is_foreign,
            has_disability: params.has_disability,
            is_veterans: params.is_veterans,
            education_level: params.education_level,
            teacher_certification: params.teacher_certification,
            teaching_experience_yn: params.teaching_experience_yn,
            employment_status: params.employment_status,
            employment_type: params.employment_type,
            start_date: searchParams.get('startDate'),
            end_date: searchParams.get('endDate'),
        }

        // 필터 카운트 정보 response
        let filter = await getTutorCountRPC(newParams)
        filter = convertKeysToCamelCase(filter)
        // 페이지 정보
        const paginationInfo = handlePageCountCommon(search, response, pageSize)

        //현재 JSON 데이터 형태로 컨버팅
        if (response.data) {
            const convertedData = response.data.map(convertTutorSnakeToCamelCase)
            response.data = {
                filterCountInfo: filter,
                tutorInfoList: convertedData,
                page: paginationInfo,
            }
            return response
        }
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}
/**
 * 교육관리 강사매칭 강사목록
 * 등록/수정 페이지
 * @param search
 * @param regionId
 * @param path
 * @returns {Promise<{data}|PostgrestFilterBuilder<*, *, GetResult<*, *, *, "*">[], *>|PostgrestFilterBuilder<*, *, GetResult<*, *, *, "*">[], *>|null>}
 */
export const fetchMatchTutorList = async (search, regionId) => {
    try {
        const tutorId = 'tutor_id'
        let query = createSupabaseQuery(table, regionId)
        query = query.order(tutorId, { ascending: false })
        const response = await query

        //현재 JSON 데이터 형태로 컨버팅
        if (response.data) {
            const convertedData = response.data.map(convertTutorSnakeToCamelCase)
            response.data = {
                tutorInfoList: convertedData,
            }
            return response
        }
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

export const getTutorsById = async tutorId => {
    try {
        const response = await supabase.from(table).select(`*,program_instructor_matching(*)`).eq('tutor_id', tutorId)
        if (response.data) {
            const convertedData = response.data.map(convertTutorSnakeToCamelCase)
            response.data = convertedData
        }
        return response.data[0]
    } catch (error) {
        console.error(error)
        return error
    }
}

export const postTutors = async tutorList => {
    try {
        const response = await supabase.from(table).insert(tutorList).select()
        return response
    } catch (error) {
        console.error('error : ', error)
        return error
    }
}

export const editTutor = async tutor => {
    try {
        const response = await supabase.from(table).update(tutor).eq('tutor_id', tutor.tutor_id)
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 수퍼베이스 삭제
 * is_delete(삭제여부) true로 변경
 * @param tutorId
 * @returns {Promise<*|null>}
 */
export const deleteTutorById = async tutorId => {
    try {
        const response = await supabase.from(table).update({ is_delete: true }).eq('tutor_id', tutorId).select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * DB에서 같은 지역 강사 동명이인 찾기 (배열)
 * @returns {Promise<*|({error: true} & "Received a generic string")>}
 * @param tutorNames
 * @param regionId
 */
export const findDuplicatedTutorNames = async (tutorNames, regionId, tutorYearArray) => {
    try {
        const batchSize = 100

        // Split tutorNames into batches of 100
        const batches = []
        for (let i = 0; i < tutorNames.length; i += batchSize) {
            batches.push(tutorNames.slice(i, i + batchSize))
        }

        const promises = batches.map(async batch => {
            let query = createSupabaseQuery(table, regionId)
            query = query.select().in('tutor_name', batch).in('registration_year', tutorYearArray)
            const response = await query

            if (response.data) {
                return response.data.map(convertTutorSnakeToCamelCase)
            }
            return []
        })

        const results = await Promise.all(promises)

        const flattenedResults = results.flat()
        return { data: flattenedResults }
    } catch (error) {
        console.error('Error finding duplicated tutor names:', error)
        return { error }
    }
}

/**
 * DB에서 같은 지역 강사 동명이인 찾기 (단건 등록)
 * @param tutorName
 * @param regionId
 * @returns {Promise<*|PostgrestFilterBuilder<*, *, GetResult<*, *, *, "*">[], *>>}
 */
export const findDuplicatedTutorName = async (tutorName, regionId, year) => {
    console.log('$$$$', year)
    try {
        let query = createSupabaseQuery(table, regionId)
        query = query.select().eq('tutor_name', tutorName).eq('registration_year', year)
        const response = await query
        if (response.data) {
            const convertedData = response.data.map(convertTutorSnakeToCamelCase)
            response.data = convertedData
        }
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 교육관리 강사매칭 강사목록 임시
 * 상세
 * @param id
 * @returns {Promise<*|PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure>}
 */
export const handleGetTutor = async id => {
    try {
        let query = supabase.from(table).select('*', { count: 'exact' })
        query = query.eq('is_delete', false)
        query = query.eq('tutor_id', id)
        return await query
    } catch (error) {
        console.error(error)
        return error
    }
}
