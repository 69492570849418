import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import { useState } from 'react'
import { Col, Form, Row, Input } from 'antd'
import SelectUi from 'components/Ui/Select/SelectUi'
import Value from 'components/Ui/Value'
// import TextField from 'components/AntD/TextField'
import style from 'assets/scss/detail.module.scss'
import DatePickerInput from 'components/AntD/DatePicker'
import dayjs from 'dayjs'

const ContractInfo = ({ contract_info, isEditOrRegisterPage, onRegistrationYearChange }) => {
    const [contractState, setContractState] = useState({
        employmentType: contract_info?.employmentType || '',
        registrationYear: contract_info?.registrationYear || '',
        startDate: contract_info?.startDate || dayjs(),
        endDate: contract_info?.endDate || dayjs(),
    })

    const handleStateChange = (field, value) => {
        setContractState(prevState => ({
            ...prevState,
            [field]: value,
        }))
        if (field === 'registrationYear' && onRegistrationYearChange) {
            onRegistrationYearChange(value)
        }
    }

    return (
        <div>
            <Title title="계약정보" />
            <div className={`${style.border_style} ${style.box_md}`}>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="고용형태" />
                            </Col>
                            <Col span={14}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item name="employmentType" initialValue={contractState.employmentType || ''}>
                                        <SelectUi
                                            name="employmentType"
                                            size="sm"
                                            onChange={value => handleStateChange('employmentType', value)}
                                            options={['직접채용', '협동조합채용']}
                                            value={contractState?.employmentType || ''}
                                        />
                                    </Form.Item>
                                ) : (
                                    <SelectUi
                                        disabled
                                        size="sm"
                                        options={['직접채용', '협동조합채용']}
                                        value={contract_info?.employmentType}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="등록연도" required={isEditOrRegisterPage} />
                            </Col>
                            {isEditOrRegisterPage ? (
                                <Col span={14} className={style.padding_y_xs}>
                                    <Form.Item
                                        rules={[{ required: true, message: '' }]}
                                        name="registrationYear"
                                        initialValue={contractState?.registrationYear || ''}
                                    >
                                        <Input
                                            name="registrationYear"
                                            val={contractState?.registrationYear || ''}
                                            style={{ padding: 12, width: 180, fontSize: 16 }}
                                            placeholder="2025"
                                            onChange={e => handleStateChange('registrationYear', e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={14} className={style.padding_y_medium}>
                                    <Value value={contract_info?.registrationYear} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row className={isEditOrRegisterPage ? style.row_item : ''}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="계약시작" required={isEditOrRegisterPage} />
                            </Col>
                            {isEditOrRegisterPage ? (
                                <Col span={14}>
                                    <Form.Item
                                        rules={[{ required: true, message: '' }]}
                                        name="startDate"
                                        initialValue={contractState?.startDate || dayjs()}
                                    >
                                        <DatePickerInput
                                            name="startDate"
                                            value={contractState?.startDate || dayjs()}
                                            onChange={date => handleStateChange('startDate', date)}
                                            defaultValue={dayjs()}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={14} className={style.padding_y_medium}>
                                    <Value type="date" value={contract_info?.startDate} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="계약만료" required={isEditOrRegisterPage} />
                            </Col>
                            {isEditOrRegisterPage ? (
                                <Col span={14}>
                                    <Form.Item
                                        rules={[{ required: true, message: '' }]}
                                        name="endDate"
                                        initialValue={contractState?.endDate || dayjs()}
                                    >
                                        <DatePickerInput
                                            name="endDate"
                                            value={contractState?.endDate || dayjs()}
                                            onChange={date => handleStateChange('endDate', date)}
                                            defaultValue={dayjs()}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={14} className={style.padding_y_medium}>
                                    <Value type="date" value={contract_info?.endDate} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default ContractInfo
